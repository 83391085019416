import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [
  // 路由重定向
  {
    path: "/",
    redirect: "daily",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/login/logout.vue"),
    meta: {
      title: "云相亲后台管理系统",
    },
  },
  {
    path: "/Home",
    name: "Home",
    redirect: "daily",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/business",
        name: "business",
        component: () => import("../views/business/business.vue"),
        meta: {
          title: "业务管理",
        },
      },
      {
        path: "/filterBusiness",
        name: "filterBusiness",
        component: () => import("../views/business/filterBusiness.vue"),
        meta: {
          title: "筛选业务",
        },
      },
      {
        path: "/wait",
        name: "wait",
        component: () => import("../views/business/wait.vue"),
        meta: {
          title: "观望",
        },
      },
      {
        path: "/purpose",
        name: "purpose",
        component: () => import("../views/business/purpose.vue"),
        meta: {
          title: "意向",
        },
      },
      {
        path: "/chengjiao",
        name: "chengjiao",
        component: () => import("../views/business/chengjiao.vue"),
        meta: {
          title: "成交",
        },
      },
      {
        path: "/quit",
        name: "quit",
        component: () => import("../views/business/quit.vue"),
        meta: {
          title: "退出",
        },
      },
      {
        path: "/check",
        name: "check",
        component: () => import("../views/business/check.vue"),
        meta: {
          title: "待核实",
        },
      },
      {
        path: "/wait",
        name: "wait",
        component: () => import("../views/business/wait.vue"),
        meta: {
          title: "观望",
        },
      },
      {
        path: "/daily",
        name: "daily",
        component: () => import("../views/information/daily.vue"),
        meta: {
          title: "日报",
        },
      },
      {
        path: "/weekly",
        name: "weekly",
        component: () => import("../views/information/weekly.vue"),
        meta: {
          title: "周报",
        },
      },
      {
        path: "/monthly",
        name: "monthly",
        component: () => import("../views/information/monthly.vue"),
        meta: {
          title: "月报",
        },
      },
      {
        path: "/member",
        name: "member",
        component: () => import("../views/member/member.vue"),
        meta: {
          title: "会员管理",
        },
      },
      {
        path: "/newMember",
        name: "newMember",
        component: () => import("../views/newMember/newMember.vue"),
        meta: {
          title: "新会员管理",
        },
      },
      {
        path: "/account",
        name: "account",
        component: () => import("../views/account/account.vue"),
        meta: {
          title: "账号管理",
        },
      },
      {
        path: "/log",
        name: "log",
        component: () => import("../views/log/log.vue"),
        meta: {
          title: "操作日志",
        },
      },
      {
        path: "/remind",
        name: "remind",
        component: () => import("../views/remind/remind.vue"),
        meta: {
          title: "新手指引",
        },
      },
      {
        path: "/appUser",
        name: "appUser",
        component: () => import("../views/appUser/appUser.vue"),
        meta: {
          title: "小程序用户",
        },
      },
      {
        path: "/usercheck",
        name: "usercheck",
        component: () => import("../views/appUser/usercheck.vue"),
        meta: {
          title: "个人资料审核"
        }
      },
      {
        path: "/requese",
        name: "requese",
        component: () => import("../views/appUser/requese.vue"),
        meta: {
          title: "择偶标准审核"
        }
      },
      {
        path: "/myHeart",
        name: "myHeart",
        component: () => import("../views/appUser/myHeart.vue"),
        meta: {
          title: "内心独白审核"
        }
      },
      {
        path: "/emotion",
        name: "emotion",
        component: () => import("../views/appUser/emotion.vue"),
        meta: {
          title: "情感经历审核"
        }
      },
      {
        path: "/personalPhoto",
        name: "personalPhoto",
        component: () => import("../views/appUser/personalPhoto.vue"),
        meta: {
          title: "个人照片审核",
        },
      },
      {
        path: "/dynamicCheck",
        name: "dynamicCheck",
        component: () => import("../views/appUser/dynamicCheck.vue"),
        meta: {
          title: "动态审核",
        },
      },
      {
        path: "/invitation",
        name: "invitation",
        component: () => import("../views/invitation/invitation.vue"),
        meta: {
          title: "邀请好友",
        },
      },
      {
        path: "/topic",
        name: "topic",
        component: () => import("../views/topic/topic.vue"),
        meta: {
          title: "话题管理",
        },
      },
      {
        path: "/sensitive",
        name: "sensitive",
        component: () => import("../views/sensitive/sensitive.vue"),
        meta: {
          title: "敏感词",
        },
      }
    ],
  },
  {
    path: "/canvasCard",
    name: "canvasCard",
    component: () => import("../components/case/canvasCard.vue"),
    // component: CanvasCard,
    meta: {
      title: "生成相亲卡",
    },
  },
  {
    path:"/ceshi",
    component: () => import("../views/ceshi.vue"),
  }
];
const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
// 路由发生变化修改页面title
router.beforeEach((to, from, next) => {
  // 改变页面title
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  // 如果用户访问的登录页面直接放行
  if (to.path === "/login") return next();
  // 判断是否登录
  let isLogin = localStorage.getItem("userInfo") ? true : false;
  if (!isLogin) {
    return next("/login");
  } else {
    next();
  }
});

export default router;
