import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import qs from 'qs'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入echarts可视化图表
import * as echarts from 'echarts/core';
// 引入柱状图图表， 图表后缀都为 Chart
import {
  BarChart,
  LineChart,
  PieChart
} from 'echarts/charts';
// 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {
  CanvasRenderer
} from 'echarts/renderers';

// 注册必须的组件
echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, LegendComponent, BarChart, LineChart,
    PieChart, CanvasRenderer
  ]
);
// 注册到原型上
Vue.prototype.$echarts = echarts;

// 事件总线
Vue.prototype.$bus = new Vue();
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");